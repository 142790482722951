import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/Page/ContentfulPage'
import withContentfulBlocks from '../components/withContentfulBlocks'

class NewsIndex extends React.Component {
  render () {
    const news = get(this.props, 'data.news.edges')
    const fiNews = news.filter(post => post.node.showOnSites.includes('fi'))

    return (
      <ContentfulPage
        blocks={this.props.blocks}
        news={fiNews}
        siteName={get(this.props, 'data.site.siteMetadata.siteName')}
        pageTitle={`${get(this.props, 'data.site.siteMetadata.siteName')} / News`}
        pageUrl={`${get(this.props, 'data.site.siteMetadata.url')}/news`}
        pageDescription='Field is a multinational, interdisciplinary team on a mission in emerging-market healthcare.'
      />
    )
  }
}

export default withContentfulBlocks(NewsIndex)

export const pageQuery = graphql`
  query FINewsQuery {
    site {
      ...SiteMetaDataFragment
    }
    page: contentfulPage(contentful_id: { eq: "4UDLtepMIdA8BWdsKYaGg1" }) {
      ...PageBlocksFragment
    }
    news: allContentfulNewsPost(sort: {fields: date , order: DESC}) {
      edges {
        node {
          title
          date
          featured
          teaser {
            childMarkdownRemark {
              html
            }
          }
          slug
          showOnSites
        }
      }
    }
  }
`
